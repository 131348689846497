import axios from "../network/axios";
export default class AuthServices {

    static async login(formData: any) {
        let data = formData;
        return await axios().post("/api/token/", data)
    }

    static async getCurrentUSer() {
        return await axios().get("/api/me");
    }

    static async logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('user');
    }

    static async register(formData: any) {
        let data = formData;
        return await axios().post("/api/register", data);
    }

    static async requestPswdReset(formData: any){
        return await axios().post("/auth/password-reset", formData);
    }

    static async resetPswd(formData: any){
        return await axios().post("/auth/password-update", formData);
    }

}