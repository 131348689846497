import { useEffect, useId } from "react";
import { components } from "react-select";
import Select from "react-select"

const customStyles = {
    control: (provided: any, state: any) => {
        // Customize the control styles here
        return {
            ...provided,
            border: '1px solid #ccc',
            borderRadius: '0.375rem',
            padding: '2px 2px',
            boxShadow: state.isFocused ? '0 0 0 2px #3498db' : null,
            color: 'purple', // Example: Change text color
        };
    },
    // Add more style overrides as needed
};


const INPUT_CLASS = "px-2 py-2 rounded-md shadow w-full mb-2 text-black  ring-1 ring-gray-300 focus:ring focus:ring-blue-200";

const NoOptionsMessage = (props: any) => {
    return (
        <components.NoOptionsMessage {...props}>
            <span className="custom-css-class">Text</span>
        </components.NoOptionsMessage>
    );
};
interface SelectComponentInterface {
    options: any,
    name: string,
    onChange?: any,
    placeholder: string,
    isMulti?: boolean,
    defaultInputValue?: any,
    defaultValue?: any,
    value?: any,
}

export function SelectComponent(props: SelectComponentInterface) {
    const { options, placeholder, name, onChange, isMulti, defaultInputValue, defaultValue, value } = props;
    useEffect(() => {

    }, [value]);

    return (
        <Select
            isMulti={isMulti}
            value={value}
            name={name}
            onChange={onChange}
            options={options}
            components={{ NoOptionsMessage }}
            placeholder={placeholder}
            styles={customStyles}
            className="text-black max-w-[300px] lg:max-w-full"
            isClearable={true}
            instanceId={useId()}
            maxMenuHeight={150}
        />
    );
}