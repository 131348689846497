import { useEffect, useState } from "react";
import { STATS_LIST, TICKET_LIST } from "./constants";
import Spinner from "./components/Spinner";
import ErrorDisplayer from "./components/ErrorDisplayer";
import TypeTicket from "./models/TypeTicketModel";
import TypeTicketCard from "./components/TicketCard";
import GenerateTypeTicketsModal from "./components/GenerateTicketsModal";
import TypeTicketServices from "./services_hooks/TypeTicketServices";
import { plainToClass } from "class-transformer";
import LogoutComponent from "./components/LogoutComponent";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";

export default function MainPage() {

    const isAuthenticated = useSelector((state: any) => state.auth.isAuthenticated);
    const selectedUser = useSelector((state: any) => state.auth.user);const [typeTickets, setTypeTickets] = useState<TypeTicket[]>([]);
    const [stats, setStats] = useState<any[]>([]);
    const [apiProcessing, setApiProcessing] = useState<boolean>(true);
    const [apiProcessingForStat, setApiProcessingForStat] = useState<boolean>(false);
    const [error, setError] = useState<string | null>();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const navigate = useNavigate();

    const fetchTypeTicketData = async () => {
        try {
            const data = await TypeTicketServices.getData();
            let typeTicketJson = data.data;
            let tempData: TypeTicket[] = [];
            tempData = typeTicketJson.map((elmt: any) => {
                return new TypeTicket({
                    pk: elmt.pk, 
                    name: elmt.name,
                    price: elmt.price
                })
            });
            setTypeTickets(tempData);
            setApiProcessing(false);
        } catch (error) {
            console.log("TICK ERROR ", error);
            setApiProcessing(false);
            setError("Une erreur s'est produite");
        }
    }


    useEffect(() => {
        if (!isAuthenticated) {
            navigate("/login");
        } else {
            if(selectedUser && selectedUser.is_superuser == "false"){
                navigate("/scan");
            } else {
                fetchTypeTicketData();
                // fetchStatsData();
                setError(null);
                setApiProcessing(true);
            }
            
        }
    }, [])

    // useEffect(() => {
    //     if (typeTickets) {
    //         setStats(STATS_LIST);
    //     }
    // }, [typeTickets])

    // useEffect(() => {
    //     if (typeTickets && stats) {
    //         let tempTypeTickets: TypeTicket[] = [];
    //         typeTickets.map((ticket: TypeTicket) => {
    //             let foundStats = stats.find((element) => element.ticket_id === ticket.pk)
    //             if (foundStats) {
    //                 ticket.stats = foundStats;
    //                 tempTypeTickets.push(ticket);
    //             }
    //         });
    //         setTypeTickets(tempTypeTickets);
    //     }
    // }, [stats])

    function closeModal() {
        setIsOpen(false);
    }

    function openModal() {
        setIsOpen(true)
    }

    if(selectedUser && selectedUser.is_superuser){
        return (
            <div className="h-screen bg-[url(images/bg1.jpg)]">
                <div className="container mx-auto p-8">
                    <div className="flex mb-12 flex-row justify-end border-b-2 border-white py-2">
                        <LogoutComponent />
                    </div>
                    <div className="flex flex-row justify-end mb-4">
                        <button
                            onClick={openModal}
                            className="rounded-md bg-red-500 px-3.5 py-2.5 w-fit text-sm font-semibold text-white shadow-sm hover:bg-red-700">
                            Générer des tickets
                        </button>
                    </div>
                    <div className="align-middle text-center flex flex-row justify-center gap-4 h-full content-center">
                        {
                            !apiProcessing && !error && typeTickets &&
                            <>
                                {
                                    typeTickets.map((ticket: TypeTicket) => <TypeTicketCard key={`${ticket.pk}-${ticket.name}`} ticket={ticket} />)
                                }
                                <GenerateTypeTicketsModal isOpen={isOpen} closeModal={closeModal} typeTickets={typeTickets} />
                            </>
                        }
                        {
                            apiProcessing && <Spinner />
                        }
                        {
                            !apiProcessing && error && <ErrorDisplayer errorMessage={error} />
                        }
                    </div>
    
                </div>
            </div>
        );
    } else{
        return (
            <div className="h-screen bg-[url(images/bg1.jpg)]">
            </div>
        );
    }
    
}