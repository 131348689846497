"use client"

import { useState, useContext, useEffect } from "react";
import { useFormik } from 'formik';
import { plainToClass } from "class-transformer";
import { useDispatch, useSelector } from "react-redux";
import { FormErrorMsg } from "./components/FormErrorMsg";
import Spinner from "./components/Spinner";
import User from "./models/UserModel";
import AuthServices from "./services_hooks/AuthServices";
import { useLocalStorage } from "./services_hooks/useLocalStorage";
import { loginSuccess } from "./auth/auth_redux/authAction";
import { useNavigate } from "react-router-dom";


export default function LoginForm() {

    const isAuthenticated = useSelector((state: any) => state.auth.isAuthenticated);
    const selectedUser = useSelector((state: any) => state.auth.user);

    const dispatch = useDispatch();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [submittionInProgress, setSubmittionInProgress] = useState<boolean>(false);
    const { setItem } = useLocalStorage();

    const navigate = useNavigate();

    useEffect(() => {
        if (isAuthenticated) {
            navigate("/");
        }
    }, [isAuthenticated]);


    const validateForm = (values: any) => {
        let errors: any = {};
        if (!values.username) {
            errors.username = 'Ce champ est requis';
        }
        if (!values.password) {
            errors.password = 'Ce champ est requis';
        }

        return errors;
    };

    const handleFormSubmit = async (values: any) => {
        setSubmittionInProgress(true);
        setError("");
        await AuthServices.login(values).then((response: any) => {
            setSubmittionInProgress(false);
            if (response.data.errors) {
                let message = "Une erreur est survenue";
                if (response.data.detail) {
                    message = response.data.detail;
                }
                setError(message);
            } else {
                let userBased = { ...response.data, ...{ token: response.data.access } };
                console.log("HHE ", userBased);
                setItem('user', JSON.stringify(userBased));
                setItem('fac-billeterie-refresh', JSON.stringify(userBased.refresh));
                setItem('fac-billeterie-access', JSON.stringify(userBased.access));
                let user: User = plainToClass(User, userBased) as User;
                dispatch(loginSuccess(user));
                setTimeout(() => {
                    navigate("/");
                },3000)
            }
        }).catch((error: any) => {
            setSubmittionInProgress(false);
            console.log("RESPONSE ERROR ", error);
            let message = "Une erreur est survenue";
            if (error.response && error.response.data && error.response.data.detail) {
                message = error.response.data.detail;
            }
            setError(message);
        });
    }

    const formik = useFormik({
        initialValues: {
            username: username,
            password: password,
        },
        validate: validateForm,
        onSubmit: values => {
            handleFormSubmit(values);
        },
    });


    return (
        <div className="bg-[url(images/bg1.jpg)]">
            <div className="sm:px-6 h-screen flex flex-col justify-center backdrop-blur-sm">
                <div className="mx-auto max-w-lg rounded-md bg-white pt-4">
                    <h1 className="text-center text-2xl font-bold text-ojav-red sm:text-3xl mb-2">
                        Se connecter
                    </h1>
                    <h6 className = "p-2 text-center text-sm font-bold text-red-500 text-wrap ">
                        {error && <p>{error}</p>}
                    </h6>
                    <form
                        onSubmit={formik.handleSubmit}
                        className="mb-0 mt-2 space-y-4 rounded-lg p-4 shadow-lg sm:p-6 lg:p-8"
                    >
                        <div>
                            <label htmlFor="email" className="sr-only">Email</label>

                            <div className="relative">
                                <input
                                    name="username"
                                    type="text"
                                    onChange={formik.handleChange}
                                    className="w-full rounded-lg border-gray-200 p-4 pe-12 text-sm shadow-sm text-black"
                                    placeholder="Email"
                                />
                                <FormErrorMsg label={formik.errors.username ?? ""} />

                            </div>
                        </div>

                        <div>
                            <label htmlFor="password" className="sr-only">Password</label>

                            <div className="relative">
                                <input
                                    name="password"
                                    type="password"
                                    onChange={formik.handleChange}
                                    className="w-full rounded-lg border-gray-200 p-4 pe-12 text-sm shadow-sm text-black"
                                    placeholder="Mot de passe"
                                />

                                <FormErrorMsg label={formik.errors.password ?? ""} />

                            </div>
                        </div>

                        <button
                            type="submit"
                            className="block w-full rounded-md bg-red-500 px-3.5 py-2.5 w-1/2 text-sm font-medium text-white shadow-sm hover:bg-red-700 text-center"
                        >
                            {submittionInProgress && (<Spinner />)}
                            {!submittionInProgress && (<span> Se Connecter </span>)}

                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}
