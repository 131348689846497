
interface TextInterface {
    label: string
}

export function FormErrorMsg(iconProps: TextInterface) {
    const label = iconProps.label;
    return (
        <div>
            <span className="text-ojav-red text-sm text-light mt-2">
                {label}
            </span>
        </div>
    );
}