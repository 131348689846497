import TypeTicket from "../models/TypeTicketModel";

interface TypeTicketCardProps {
    ticket: TypeTicket;
}

export default function TypeTicketCard(ticketCardProps: TypeTicketCardProps) {
    const {ticket} = ticketCardProps;
    return (
        <div className="bg-white shadow-2xl shadow-red-500/40 rounded-md text-center w-1/4 h-fit">
            <div className="p-4 border-b border-slate-500">
                {ticket.name}
            </div>
            <div className="p-4">
                {
                    ticket.stats && 
                    <>
                        <p>
                            <span className="font-bold	text-2xl text-red-500 me-2">
                                {ticket.stats.nb_genere}
                            </span>
                            <span className="text-sm text-slate-500">
                                Générés
                            </span>
                        </p>
                        <p>
                            <span className="font-bold	text-2xl text-red-500 me-2">
                                {ticket.stats.nb_vendu}
                            </span>
                            <span className="text-sm text-slate-500">
                                Participants
                            </span>
                        </p>
                    </>
                    
                }
            </div>
        </div>
    );
}