import axios from "../network/axios";

export default class TypeTicketServices {

    static async getData(params?: any) {
        return await axios().get("/api/ticket_types", {
            params: params,
        });
    }

    static async getStats(params?: any) {
        return await axios().get("/api/ticket_types/stats/", 
        {
            params: params,
        });
    }

    static async generateTickets(data?: any) {
        return await axios().post("/api/tickets/generate/", data, {responseType: 'blob'});
    }

    static async scan(data?: any) {
        return await axios().put("/api/tickets/"+data);
    }

}