import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../auth/auth_redux/authAction";
import { useLocalStorage } from "../services_hooks/useLocalStorage";

export default function LogoutComponent(){
    
    const isAuthenticated = useSelector((state: any) => state.auth.isAuthenticated);
    const selectedUser = useSelector((state: any) => state.auth.user);
    const { removeItem } = useLocalStorage();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const logoutUser = () => {
        removeItem('user');
        removeItem('fac-billeterie-refresh');
        removeItem('fac-billeterie-access');
        dispatch(logout());
        navigate("/login");
    }

    return (
        <button
        onClick={logoutUser}
        className="rounded-md bg-cyan-500 px-3.5 py-2.5 w-fit text-sm font-semibold text-white shadow-sm hover:bg-cyan-700">
            Se déconnecter
        </button>
    );
}