import LoaderSvgComponent from "./LoaderSvgComponent";

export default function Spinner(props: any) {

    const {customClassName} = props;

    return (
        <div className={`flex flex-row justify-center ${customClassName}`}>
            <span>
                <LoaderSvgComponent />
            </span>
            Chargement...
        </div>
    );
}