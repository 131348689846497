import { Fragment, useEffect, useState } from "react";
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from "@headlessui/react";
import { useFormik } from "formik";
import { FormErrorMsg } from "./FormErrorMsg";
import { SelectComponent } from "./SelectComponent";
import TypeTicket from "../models/TypeTicketModel";
import Spinner from "./Spinner";
import TypeTicketServices from "../services_hooks/TypeTicketServices";


interface GenerateTypeTicketsModalPropsInterface {
    isOpen: boolean;
    typeTickets: TypeTicket[],
    closeModal: Function
}

export default function GenerateTypeTicketsModal(generateTypeTicketsModalPropsInterface: GenerateTypeTicketsModalPropsInterface) {
    const { isOpen, closeModal, typeTickets } = generateTypeTicketsModalPropsInterface;

    const [ticket_type, setTypeTypeTicket] = useState();
    const [typeTypeTicketOptions, setTypeTypeTicketOptions] = useState<any[]>([]);
    const [apiProcessing, setApiProcessing] = useState<boolean>(false);
    const [error, setError] = useState("");

    useEffect(() => {
        console.log("LOADED TYPE TICKERS ", typeTickets)
        if (typeTickets) {
            setTypeTypeTicketOptions(TypeTicket.toOptions(typeTickets));
        }
    }, [])

    const onModalClosed = () => {
        closeModal();
    }

    const handleFormSubmit = async (values: any) =>  {
        setApiProcessing(true);
        setError("");
        await TypeTicketServices.generateTickets(values).then( async (response: any) => {
            setApiProcessing(false);
            if (response.data.errors) {
                let message = "Une erreur est survenue";
                if (response.data.detail) {
                    message = response.data.detail;
                }
                setError(message);
            } else {
                const url = URL.createObjectURL(response.data);
                window.open(url, '_blank');
            }
        }).catch((error: any) => {
            console.log("ERROR ", error);
            setApiProcessing(false);
            setError("");
        });
    }

    const validateForm = (values: any) => {
        let errors: any = {};
        if (!values.tickets_count) {
            errors.tickets_count = 'Ce champ est requis';
        } else {
            if (values.tickets_count < 1) {
                errors.tickets_count = `Le nombre de ticket à générer doit être supérieur à 0`;
            }
        }

        return errors;
    };

    const formik = useFormik({
        initialValues: {
            tickets_count: 0,
            ticket_type: null,
        },
        validate: validateForm,
        onSubmit: values => {
            handleFormSubmit(values);
        },
    });

    return (
        <>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={onModalClosed}>
                    <TransitionChild
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </TransitionChild>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <TransitionChild
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <DialogPanel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <DialogTitle
                                        as="h3"
                                        className="text-lg font-medium leading-6 text-gray-900 mb-8"
                                    >
                                        Générer des tickets
                                    </DialogTitle>


                                    <form
                                        onSubmit={formik.handleSubmit}
                                        className="mb-0 mt-2 space-y-4"
                                    >
                                        <div>
                                            <label htmlFor="type_ticket" className="sr-only">Type de ticket à générer</label>
                                            <div>
                                                <SelectComponent
                                                    name="ticket_type"
                                                    onChange={(value: any) => {
                                                        if (value) {
                                                            console.log("TYPE TICK ", value);
                                                            formik.setFieldValue("ticket_type", value?.value);
                                                            setTypeTypeTicket(value.value);
                                                        }
                                                    }}
                                                    options={typeTypeTicketOptions}
                                                    placeholder="Type de ticket"
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <label htmlFor="tickets_count" className="sr-only">Nombre de ticket à générer</label>

                                            <div className="">
                                                <input
                                                    name="tickets_count"
                                                    type="number"
                                                    onChange={formik.handleChange}
                                                    className="w-full rounded-lg p-4 pe-12 text-sm shadow-sm text-black ring-1 ring-gray-300 focus:ring focus:ring-blue-200"
                                                    placeholder="Nombre de ticket"
                                                />
                                                <FormErrorMsg label={formik.errors.tickets_count ?? ""} />
                                            </div>
                                        </div>
                                        <div className="mt-4 md:flex md:justify-end gap-2">
                                            <div>
                                                <button
                                                    type="submit"
                                                    className="inline-flex justify-center rounded-md border border-transparent bg-red-500 px-4 py-2 text-sm font-medium text-white hover:bg-red-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                                >
                                                    {
                                                        apiProcessing && <Spinner customClassName={"text-white"} />
                                                    }
                                                    {
                                                        !apiProcessing && <span>Générer</span>
                                                    }
                                                    
                                                </button>
                                            </div>

                                            <div>
                                                <button
                                                    type="button"
                                                    className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                                    onClick={onModalClosed}
                                                >
                                                    Annuler
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </DialogPanel>
                            </TransitionChild>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
}