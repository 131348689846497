
export default class Helpers {

    static firstLetterCapitalized(str: string) {
        if (str) {
            const arr = str.split(" ");
            for (var i = 0; i < arr.length; i++) {
                arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
            }
            const str2 = arr.join(" ");
            return str2;
        }
        return str;
    }

    static convertValuesToInt(inputObj: any) {
        const resultObj: any = {};

        for (const key in inputObj) {
            if (inputObj.hasOwnProperty(key)) {
                const value = inputObj[key];
                const intValue = parseInt(value, 10);

                if (!isNaN(intValue)) {
                    resultObj[key] = intValue;
                } else {
                    resultObj[key] = value;
                }
            }
        }

        return resultObj;
    }

    static convertToTime(timeString: string) {
        // Split the timeString into hours, minutes, and seconds
        const [hours, minutes, seconds] = timeString.split(":").map(Number);

        // Create a new Date object with the current date and extracted time components
        const currentTime = new Date();
        currentTime.setHours(hours, minutes, seconds);
        return currentTime;
    }

    static extractIds(table: any[]): any[] {
        if(table){
            return table.map(item => item.id);
        } else {
            return [];
        }
    }

    static formatDate(date: any) {
        // Extract day, month, and year from the date object
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
    
        // Return the formatted date string
        return `${day}/${month}/${year}`;
    }
}

