import axios from "axios";
import { API_URL } from "../constants";

function getAxiosConfig() {
  // eslint-disable-next-line prefer-const
  let instanceConfig = {
    baseURL: API_URL,
    timeout: 5 * 60000,
    headers: {},
  };

  const axiosInstance = axios.create(instanceConfig);

  axiosInstance.interceptors.request.use((config) => {
    const currentUser = localStorage.getItem("user");
    if(currentUser) {
      let token = JSON.parse(currentUser).token;
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    return config;
  });

  axiosInstance.interceptors.response.use(
    (config) => {
      // Intercept get `/token` response and globally set token in axios header.
      if (config?.data?.access && config?.data?.refresh) {
        localStorage.setItem("fac-billeterie-access", config.data.access);
        localStorage.setItem("fac-billeterie-refresh", config.data.refresh);
      }
      return config;
    },
    async (error) => {
      // "ERR_BAD_REQUEST"
      const originalConfig = error.config;
      // Intercept for token expiration error, and globally override token in
      // axios header.
      // On token expiration
      if (error?.response?.status !== 401) {
        return Promise.reject(error);
      }
      if (error?.response?.data?.code === "token_not_valid") {
        localStorage.removeItem("fac-billeterie-access");
        localStorage.removeItem("fac-billeterie-refresh");
        localStorage.removeItem("user");
        window.location.href = "/login";        
      }
    },
  );

  return axiosInstance;
}




// import axios from "axios";
// import { API_URL } from "../constants";

// function getAxiosConfig(withHeader = true) {
//   let instanceConfig = {
//     baseURL: API_URL,
//     // timeout: 10000,
//     headers: {},
//   };

//   if (withHeader) {
//     const currentUser = localStorage.getItem("user");
    
//     if(currentUser) {
//       let token = JSON.parse(currentUser).token;
//       if (token) {
//         instanceConfig.headers.Authorization = `Bearer ${token}`;
//       }
//     }
    
//   }

//   const axiosInstance = axios.create(instanceConfig);

//   axiosInstance.interceptors.request.use((config) => {
//     return config;
//   });

//   // axiosInstance.interceptors.response.use(
//   //   (config) => {
//   //     if (config?.data?.access) {
//   //       localStorage.setItem("fac-access-token", config.data.access);
//   //     }
//   //     return config;
//   //   },
//   //   async (error) => {
//   //     // "ERR_BAD_REQUEST"
//   //     const originalConfig = error.config;
//   //     if (
//   //       error?.response?.status === 401 &&
//   //       error?.response?.data?.code === "token_not_valid"
//   //     ) {
//   //       if (
//   //         error?.response?.data?.messages?.length &&
//   //         error.response.data.messages[0].token_class === "AccessToken"
//   //       ) {
//   //         localStorage.setItem("fac-access-token", config.data.access);
//   //       }
//   //       else {
//   //         window.location.href = "/";
//   //       }
//   //     }
//   //     return Promise.reject(error);
//   //   },
//   // );

//   return axiosInstance;
// }

export default getAxiosConfig;
