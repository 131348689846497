import Helpers from "../Helpers";

export interface TypeTicketConstructorInterface {
  pk: any;

  name: string;

  price: string;

  stats?: any

}

export default class TypeTicket {
  pk: any;

  name: string;

  price: string;

  stats: any;


  constructor({ name, pk, price, stats}: TypeTicketConstructorInterface) {
    this.pk = pk;
    this.price = price;
    this.name = name;
    this.stats = stats;
  }

  static toOptions(typeTickets:TypeTicket[]) : any[]{
    let options = typeTickets.map((elmt: any) => {
        return { value: elmt.pk, label: Helpers.firstLetterCapitalized(`${elmt.name} - ${elmt.price}`) };
    });
    return options;
  }
}
