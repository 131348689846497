import { plainToClass } from "class-transformer";
import { LOGIN_SUCCESS, LOGOUT } from "./authAction";
import User from "../../models/UserModel";

let user = null;

if (typeof window !== 'undefined') {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
        user = plainToClass(User, JSON.parse(storedUser)) ;
    }
}

const initState = {
    user: user,
    isAuthenticated: user ? true : false,
};

export const authReducer = (state = initState, action: any) => {
    switch (action.type) {
        case LOGIN_SUCCESS:
            return {
                ...state,
                user: action.payload,
                isAuthenticated: true,
            }
        case LOGOUT:
            return {
                ...state,
                user: null,
                isAuthenticated: false,
            }
        default:
            return state;
    }
};