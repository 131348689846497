export default class User {
    id?: number;
    code?: string;
    nom?: string;
    prenom?: string;
    telephone?: string;
    email?: string;
    token?: string;
    access?: string;
    refresh?: string;
    is_superuser? : boolean;

  constructor(
    id: number,
    token: string,
    code?: string,
    telephone?: string,
    email?: string,
    nom?: string,
    prenom?: string,
    access?: string,
    refresh?: string,
    is_superuser?: boolean,
  ) {
    this.id = id;
    this.code = code; 
    this.nom = nom; 
    this.prenom = prenom; 
    this.telephone = telephone; 
    this.email = email; 
    this.token = token; 
    this.access = access; 
    this.refresh = refresh; 
    this.is_superuser = is_superuser; 
  }
}
