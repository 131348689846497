import TypeTicket from "./models/TypeTicketModel";

export const TICKET_LIST:TypeTicket[] = [
    new TypeTicket({pk: 1, name: "PASS JOUR 1", price: "5000"}),
    new TypeTicket({pk: 2, name: "PASS JOUR 2", price: "5000"}),
    new TypeTicket({pk: 3, name: "PASS JOUR 3", price: "5000"}),
    new TypeTicket({pk: 4, name: "PASS 3 JOURS", price: "15000"}),
]

export const STATS_LIST = [
    {ticket_id:1, nb_vendu: 5100, nb_genere: 6100},
    {ticket_id:2, nb_vendu: 4200, nb_genere: 7200},
    {ticket_id:3, nb_vendu: 9300, nb_genere: 10300},
    {ticket_id:4, nb_vendu: 5400, nb_genere: 6400},
]

export const API_URL = process.env.REACT_APP_API_KEY;
// export const API_URL = "https://api.festivalartfrocaribbean.com/";
// export const API_URL = " http://127.0.0.1:8000/";
