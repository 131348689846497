import { useState } from 'react';
import { Scanner } from '@yudiel/react-qr-scanner';
import TypeTicketServices from './services_hooks/TypeTicketServices';
import Spinner from './components/Spinner';
import CheckedSvgComponent from './components/CheckedSvgComponent';
import CrossSvgComponent from './components/CrossSvgComponent';
import ErrorDisplayer from './components/ErrorDisplayer';

function ScanPage() {
  const [shouldScan, setShouldScan] = useState(false);
  const [apiProcessing, setApiProcessing] = useState<boolean>(false);
  const [isTicketValide, setIsTicketValide] = useState<boolean>(false);
  const [justScanned, setJustScanned] = useState<boolean>(false);
  const [error, setError] = useState("");

  const showHideScan = () => {
    if (!apiProcessing) {
      setShouldScan(!shouldScan);
      setIsTicketValide(false);
      setJustScanned(false);
      setError("");
    }
  };

  const handleScannedCode = async (result: any) => {
    setShouldScan(false);
    console.log("SCAN RESULT ", result);
    console.log("SCAN RESULT ", result[0].rawValue);
    let code = result[0].rawValue;
    await TypeTicketServices.scan(code).then((response: any) => {
      setApiProcessing(false);
      setJustScanned(true);
      if (response.data.errors) {
        let message = "Une erreur est survenue";
        if (response.data.detail) {
          message = response.data.detail;
        }
        setError(message);
      } else {

      }
    }).catch((error: any) => {
      console.log("RESPONSE ", error);
      let message = "Une erreur s'est produite, Veuillez réessayer";
      if (error.response && error.response.status == 404) {
        message = "Code QR non trouvé";
        setIsTicketValide(false);
        setJustScanned(true);
      } else {
        setJustScanned(false);
      }
      setApiProcessing(false);
      setError(message);
    });

  }

  return (
    <div className='min-h-screen'>
      <div className="flex items-center justify-center my-2">
        <button
          className="px-4 py-2 text-white bg-blue-500 rounded-lg hover:bg-blue-700"
          onClick={showHideScan}
        >
          {`${shouldScan ? "Arrêter le scan" : "Scanner un ticket"}`}
        </button>
      </div>
      {
        shouldScan &&
        <div className="flex flex-row justify-center">
          <div className=''>
            <Scanner paused={!shouldScan} onScan={handleScannedCode} />
          </div>
        </div>
      }
      {
        apiProcessing && <Spinner customClassName={"text-white"} />
      }
      {
        !apiProcessing && isTicketValide && !shouldScan && justScanned &&
        <div className="flex items-center justify-center my-2">
          <CheckedSvgComponent additionnalClass="h-20 w-20" />
        </div>
      }
      {
        !apiProcessing && !isTicketValide && !shouldScan && justScanned &&
        <div className="flex items-center justify-center my-2">
          <CrossSvgComponent additionnalClass="h-20 w-20" />
        </div>
      }
      {
        !apiProcessing && error &&
        <div className="flex items-center justify-center my-2">
          <ErrorDisplayer errorMessage={error} />
        </div>

      }
    </div>

  );
}

export default ScanPage;